@import url("https://use.typekit.net/adu4lvx.css");

$font-family-motiva: 'motiva-sans'!important;
$font-family-alkaline: 'alkaline', sans-serif;

$font-color: #fff;
$countdown-color: #FCEA96;

.GivingTuesdayCoutdown {
    &-header {
        color: $font-color;
        font-size: 40px;
        font-weight: 600;
        line-height: 34px;
        text-shadow: 8px 8px 40px rgba(0, 0, 0, 0.25);
        font-family: $font-family-alkaline;
        margin-bottom: 16px;

        @media screen and (min-width: 768px) {
            font-size: 120px;
            line-height: 110px;
            margin-bottom: 32px;
        }

        strong {
            font-weight: 800;
        }
    }

    &-subHeader {
        color: $font-color;
        font-size: 28px;
        font-weight: 800;
        line-height: 28px;
        font-family: $font-family-motiva;
        text-transform: uppercase;
        margin-bottom: 32px;

        @media screen and (min-width: 768px) {
            font-size: 60px;
            line-height: 60px;
            margin-bottom: 64px;
        }
    }

    &-days {
        color: #FCEA96;
    }

    &-img {
        max-width: 156px;
        filter: drop-shadow(8px 8px 40px rgba(0, 0, 0, 0.25));

        @media screen and (min-width: 768px) {
            max-width: 300px;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}