@import "https://use.typekit.net/adu4lvx.css";
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.GivingTuesdayCoutdown-header {
  color: #fff;
  text-shadow: 8px 8px 40px #00000040;
  margin-bottom: 16px;
  font-family: alkaline, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 34px;
}

@media screen and (width >= 768px) {
  .GivingTuesdayCoutdown-header {
    margin-bottom: 32px;
    font-size: 120px;
    line-height: 110px;
  }
}

.GivingTuesdayCoutdown-header strong {
  font-weight: 800;
}

.GivingTuesdayCoutdown-subHeader {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: 800;
  line-height: 28px;
  font-family: motiva-sans !important;
}

@media screen and (width >= 768px) {
  .GivingTuesdayCoutdown-subHeader {
    margin-bottom: 64px;
    font-size: 60px;
    line-height: 60px;
  }
}

.GivingTuesdayCoutdown-days {
  color: #fcea96;
}

.GivingTuesdayCoutdown-img {
  filter: drop-shadow(8px 8px 40px #00000040);
  max-width: 156px;
}

@media screen and (width >= 768px) {
  .GivingTuesdayCoutdown-img {
    max-width: 300px;
  }
}

.GivingTuesdayCoutdown-img img {
  max-width: 100%;
  height: auto;
}
/*# sourceMappingURL=index.css.map */
